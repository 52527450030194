

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTableRow-root .dark {
  background-color: lightgray;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-confirm-alert-overlay {
  z-index: 2000 !important;
}

.react-confirm-alert-body {
  width: unset !important;
  max-width: 400px;
}

.details.flex-table{
  grid-template-columns: auto auto;
}

.details.title{
  grid-column-start: 1;
  grid-column-end: -1;
}

.flex-cell.first{
  grid-column-start: 1;
}

.flex-table {
  display: grid;
  gap: 0.5em;
  align-items: center;
}

.flex-header {
  font-weight: bold;
}

.flex-row.flex-header.first {
  min-width:10em;
}
.flex-row.num {
  grid-column-start:2;
  font-weight: bold;
}

.flex-row.button {
  grid-column-start: 1;

  /*grid-column-start: none;*/
}

.flex-row.separator {
  grid-column-start: 1;
  grid-column-end: -1;
}
.hidden {
  display: none !important;
}

.place {
  /*background-color:black;
  color:white;*/
  /*padding:5px;*/
  font-size: large;
  color: #fff !important;
  background-color:#673ab7 !important;
}

.cell .content {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell {
  display: flex;
  align-items: flex-end;
  padding: 0 5px;
  overflow: hidden;
}

.cell1 .content1 {

}

.MuiTableRow-root.dark {
  background-color: #f7f7f7;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
  -moz-appearance: textfield;
}

.unused {
  background-color: #6E6E6E;
}

.MuiTableCell-root.rr-cell {
  text-align: center;
}

.rr-player {
}

.react-calendar {
  border: none !important;
}
.react-calendar__month-view__days .event-day {
  background-color: #006edc;
  border-radius: 50%;
  color:white;
}

.result-top {
  border-bottom:1px solid;
}
.result-bottom {
  white-space: pre;

}

.rankChange {
  vertical-align: middle;
}

.positive {
  color:green;
}

.negative {
  color:red
}

@media all and (min-width: 801px) {
  .flex-header.details.label {
    display: none;
  }
}

@media all and (max-width: 800px) {
  .details.flex-table{
    grid-template-columns: auto;
  }

  
  .flex-header {
    display:none;
  }
  .players.flex-table {
    grid-template-columns: min-content min-content auto !important;
  }
  .flex-header.details {
    display: none;
  }

  .flex-row.details {
    grid-column: 3;
  }
  .flex-row.details.empty {
    display: none;
  }
}