/*********************************************/
/*   stream                                  */
/*********************************************/
table.stream {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: none;
  width: 100%;
  color: white;
}

.stream-container .cell-back {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	transform: skewX(-20deg);
	z-index:-1;
}

.stream-container .header {
	transform: skewX(-20deg);
	background-image: linear-gradient(to right, #3486C1dd,#3486C1dd 70%, #D4DDE4DD);
  color:white;
  padding-left: 20px;
  border-radius: 5px 0 0 5px;  
  font-weight: normal;
}


.stream-container h1 {
	xcolor: #001136DD;
  margin : 0;
  font-weight: normal;
	/* #background-image: linear-gradient(to right, #0f0459DD , #1f06c9DD); */
}
.stream-container h2 {
	/* #background-image: linear-gradient(to right, #FFFFFFDD , #1f06c9DD);*/
  background-image: linear-gradient(to right, #0674D1DD,#0674D1DD 70%, #D4DDE4DD);
  font-weight: normal;
  margin:0;
  padding-left: 20px;
 border-radius: 5px 0 0 0px;  
}

.stream-container .title {
	transform: skewX(-20deg);
    position:relative;
	color:white;
	text-align: center;
}

table.stream td, th {
  xborder: 1px solid #dddddd;
  border:none;
  text-align: left;
  padding: 8px;
  position:relative
}

table.stream td .cell-back {
  border-bottom: 4px solid #3486C1dd;
}

table.stream tr>*:last-child .cell-back{
  border-radius: 0 5px 5px 0 ;  
}
table.stream tr>*:first-child .cell-back{
  border-radius: 5px 0 0 5px;  
}

table.stream tr th {
  color: #001136DD;
}

table.stream tr th .cell-back{
  background-image: linear-gradient(#D4DDE4DD 5%, #BECAE6 20%, #D4DDE4DD);
  /* #background-color: #D4DDE4DD #B4C4E2DD #D4DDE4DD; */
}

table.stream tr:nth-child(even) td .cell-back{
  background-color: #001136DD;
}
table.stream tr:nth-child(odd)  td .cell-back {
  background-color: #0E2342DD;
}

table.stream tr:nth-child(even) td.number .cell-back{
	background-image: linear-gradient(to right, #0674D1DD, #0053A7DD 70%, #001136DD);
}
table.stream tr:nth-child(odd) td.number .cell-back{
	background-image: linear-gradient(to right, #0674D1DD, #0053A7DD 70%, #0E2342DD);
}

table.stream .number {
  text-align:center;
}
 
.stream-container {
  padding: 15px;
}

.powered-by {
  display: inline;
  float:right;
  color: white;
  background-color: #337CB3;
  margin-right: 20px;
  padding: 0 5px 0 5px;
  font-size: larger;
}

/*********************************************/